import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css'; // Import your CSS file

import DashboardIcon from '../images/footer/Dashboard.svg';
import NFTsIcon from '../images/footer/NFTswhite.svg';
import UniversitiesIcon from '../images/footer/University.svg';
import ProfileIcon from '../images/footer/Profile.svg';
import MenuIcon from '../images/footer/Menu.svg';

const Footer = () => {
  const location = useLocation();

  return (
    <footer className="footer">
      <div className="footer-link">
        <Link to="/dashboard" className={`unselected-link ${location.pathname !== '/dashboard' ? 'selected-link' : ''}`}>
          <div className={`unselected-icon ${location.pathname === '/nfts' ? 'nft-selected' : ''}`}>
          <img src={DashboardIcon} alt="Dashboard" />
          </div>
          Dashboard
        </Link>
      </div>
      <div className="footer-link">
        <Link to="/nfts" className={`unselected-link ${location.pathname === '/nfts' ? 'selected-link' : ''}`}>
          <div className={`selected-icon ${location.pathname === '/nfts' ? 'nft-selected' : ''}`}>
            <img src={NFTsIcon} alt="NFTs" />
          </div>
          <span style={{ color: '#0579BC' }}>NFTs</span>
        </Link>
      </div>
      <div className="footer-link">
        <Link to="/universities" className={`unselected-link ${location.pathname !== '/universities' ? 'selected-link' : ''}`}>
          <div className={`unselected-icon ${location.pathname === '/nfts' ? 'nft-selected' : ''}`}>
          <img src={UniversitiesIcon} alt="Universities" />
          </div>
          Universities
        </Link>
      </div>
      <div className="footer-link">
        <Link to="/profile" className={`unselected-link ${location.pathname !== '/profile' ? 'selected-link' : ''}`}>
          <div className={`unselected-icon ${location.pathname === '/nfts' ? 'nft-selected' : ''}`}>
          <img src={ProfileIcon} alt="Profile" />
          </div>
          Profile
        </Link>
      </div>
      <div className="footer-link">
        <Link to="/menu" className={`unselected-link ${location.pathname !== '/menu' ? 'selected-link' : ''}`}>
         <div className={`unselected-icon ${location.pathname === '/nfts' ? 'nft-selected' : ''}`}>
          <img src={MenuIcon} alt="Menu" />
          </div>
          Menu
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
