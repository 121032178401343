import React from 'react';
import ButtonWithIcon from './ButtonWithIcon';

// Import your custom SVG icons from the public directory with relative paths
import ClassDiscussionIcon from '../images/icons/Class Discussion.svg';
import DiplomaIcon from '../images/icons/diploma.svg';
import ExtracurricularActivitesIcon from '../images/icons/Extracurricular Activites, Volunteer Work, Letters of Recommendation.svg';
import PerformanceMetricsIcon from '../images/icons/Performance Metrics.svg';
import PresentationsIcon from '../images/icons/Presentations.svg';
import TestsIcon from '../images/icons/Tests, Quizzes, Homework Assignments.svg';
import TranscriptsIcon from '../images/icons/Transcripts.svg';
import Footer from './Footer';

const Home = () => {
  return (
    <div className="iphone-border">
      <div className="iphone-content">
        <div className="section-text">
            <h1>NFTs</h1>
          </div>
        <div className="home-container">
          
          <ul className="link-list">
            {LINKS.map((link) => (
              <li className="link-item" key={link.id}>
                <ButtonWithIcon icon={link.icon} text={link.text} to={link.to} sectionText={link.text} />
              </li>
            ))}
          </ul>
        </div>
        <Footer /> {/* Include the Footer component */}
      </div>
    </div>
  );
};

const LINKS = [
  { id: 1, to: '/gallery/diplomas', icon: DiplomaIcon, text: 'Diplomas and Certificates' },
  { id: 2, to: '/gallery/discussion', icon: ClassDiscussionIcon, text: 'Class Discussion' },
  { id: 3, to: '/gallery/transcripts', icon: TranscriptsIcon, text: 'Transcripts' },
  { id: 4, to: '/gallery/presentations', icon: PresentationsIcon, text: 'Presentations' },
  { id: 5, to: '/gallery/tests', icon: TestsIcon, text: 'Tests, Quizzes, Homework and Assignments' },
  { id: 6, to: '/gallery/metrics', icon: PerformanceMetricsIcon, text: 'Performance Metrics' },
  {
    id: 7,
    to: '/gallery/extracurricular',
    icon: ExtracurricularActivitesIcon,
    text: 'Extracurricular Activities, Clubs, Volunteer Work, Letters of Recommendation',
  },
];

export default Home;
