import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Gallery.css';
import Footer from './Footer'; // Import the Footer component

// Define the contractMappings object with section IDs as keys
const contractMappings = {
  diplomas: 'terra1gq0hfu4mgxatcdxh64sjj5cr53pt3u5wc5gl4uryz05q5q5nelkqhkdmlx',
  discussion: 'terra19taeyk8neetfs5ujs929mxd2c2s0d7kn0ky476w8f8gqyqk9t8zs7hy06r',
  transcripts: 'terra1lm5v3mnpthpv6nuth0shcrh038gv2vn0mcl50zcryl2uafr9jr9strzdar',
  presentations: 'terra1jdwgdpvpzxuctz47ut5v7tsx7nzxmadh8n6jd5wdcel06elum6pqa3vpqk',
  tests: 'terra15fr6mxc40kk8mkwy4enzp45gxwwalj5z52lazj630tn85cf3etasseluc9',
  metrics: 'terra1xmzxr30s9hc6gt07hnujynhzu4fcpa8mekdswt4kyj0nnw6clwwqencx8a',
  extracurricular: 'terra1hjuskfps2h6tt0cgp32axeygysdyr0vq3e9jcma067227hy29nnscdyxsw',
};

const HeadingMappings = {
  diplomas: 'Diplomas and Certificates',
  discussion: 'Class Discussion',
  transcripts: 'Transcripts',
  presentations: 'Presentations',
  tests: 'Tests, Quizzes, Homework and Assignments',
  metrics: 'Performance Metrics',
  extracurricular: 'Extracurricular Activities, Clubs, Volunteer Work, Letters of Recommendation',
};

const Gallery = () => {
  const { section } = useParams();
  const [images, setImages] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const sectionText = HeadingMappings[section];
  const navigate = useNavigate();
  useEffect(() => {
    const contractAddress = contractMappings[section];

    const fetchDataForSection = async () => {
      const tokenListUrl = `https://pisco-lcd.terra.dev/cosmwasm/wasm/v1/contract/${contractAddress}/smart/ewogICJhbGxfdG9rZW5zIjoge30KfQ==`;

      try {
        const response = await axios.get(tokenListUrl);
        const tokens = response.data.data.tokens;

        const imageArray = [];
        const metadataArray = [];

        for (const token of tokens) {
          const query = {
            nft_info: { token_id: token },
          };

          const queryUrl = `https://pisco-lcd.terra.dev/cosmwasm/wasm/v1/contract/${contractAddress}/smart/${btoa(
            JSON.stringify(query)
          )}`;

          try {
            const tokenDataResponse = await axios.get(queryUrl);
            const tokenData = tokenDataResponse.data.data;

            imageArray.push(tokenData.extension.image);

            metadataArray.push({
              name: tokenData.extension.name,
              description: tokenData.extension.description,
              external_url: tokenData.extension.external_url,
              query: query,
              contractAddress: contractAddress,
              token_id: token, // Include the token_id here
            });
          } catch (error) {
            console.error('Error fetching token data:', error);
          }
        }

        setImages(imageArray);
        setMetadata(metadataArray);
      } catch (error) {
        console.error('Error fetching token list:', error);
      }
    };

    fetchDataForSection();
  }, [section]);

 return (
  <div className="iphone-border">
   <div className="iphone-content">
    <div className="section-text">
      <h1>{sectionText}</h1>
    </div>
    <div className="back-button-container">
            <button
              onClick={() => navigate(-1)} // Use navigate function to go back
              className="back-link"
              style={{ color: '#0FA7FF', border: 'none' }}
            >
              &#8592; Back
            </button>
      </div>
          
      <div className="content-container" style={{ overflow: 'auto' }}>
              {images.map((image, index) => (
                <div key={index} className="image-container">
                  {metadata[index] ? (
                    <>
                      {/* Link to the new route with the selected token_id as a URL parameter */}
                      <Link
                        to={`/nft-content/${metadata[index].contractAddress}/${metadata[index].token_id}?section=${section}`}
                      >
                        <img
                          src={image}
                          alt={`Item ${index + 1}`}
                          className="responsive-image"
                          style={{ border: '1px solid #ccc', borderRadius: '8px', width: '200px', height: '200px' }}
                        />
                      </Link>
                      <div className="gallery-text">
                        <div className="label">Name:</div>
                        <div className="value">{metadata[index].name}</div>
                        <div className="label">Description:</div>
                        <div className="value">{metadata[index].description}</div>
                        <div className="label">Content:</div>
                        <div className="value">
                          <Link
                            to={`/nft-content/${metadata[index].contractAddress}/${metadata[index].token_id}?section=${section}`}
                          >
                            View Content
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Metadata for this item is not available.</p>
                  )}
                
                </div>
              ))}

            </div>
             <Footer /> {/* Moved the Footer here */}
        </div>
       


    </div>
);
};

export default Gallery;
