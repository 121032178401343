import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './NFTDetailView.css';
import Footer from './Footer'; // Import the Footer component

const NFTDetailView = () => {
  const { contractAddress, tokenId } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('content');
  const [tokenData, setTokenData] = useState(null);

  useEffect(() => {
    const fetchDataForNFT = async () => {
      const query = {
        nft_info: { token_id: tokenId },
      };

      const queryUrl = `https://pisco-lcd.terra.dev/cosmwasm/wasm/v1/contract/${contractAddress}/smart/${btoa(
        JSON.stringify(query)
      )}`;

      try {
        const tokenDataResponse = await axios.get(queryUrl);
        const tokenData = tokenDataResponse.data.data;
        setTokenData(tokenData);
      } catch (error) {
        console.error('Error fetching token data:', error);
      }
    };

    fetchDataForNFT();
  }, [contractAddress, tokenId]);

  if (!tokenData) {
    return <div>Loading...</div>;
  }

return (
  <div className="iphone-border">
    <div className="iphone-content">
      <div className="section-text">
        <h1>{tokenData.extension.name}</h1>
      </div>
      <div className="back-button-container">
        <button
          onClick={() => navigate(-1)} // Use navigate function to go back
          className="back-link"
          style={{ color: '#0FA7FF', border: 'none' }}
        >
          &#8592; Back
        </button>
      </div>

      <div className="nft-detail-container">
        <div className="tabs">
          <button
            className={`tab-button ${selectedTab === 'content' ? 'active' : ''}`}
            onClick={() => setSelectedTab('content')}
          >
            NFT Content
          </button>
          <button
            className={`tab-button ${selectedTab === 'data' ? 'active' : ''}`}
            onClick={() => setSelectedTab('data')}
          >
            NFT Data
          </button>
        </div>

        <div className="content-container" style={{ overflow: 'auto' }}>
          {selectedTab === 'content' ? (
            <>
              <div className="nft-content">
                <div className="nft-image-container">
                  <img
                    src={tokenData.extension.image_data}
                    alt={`Item ${tokenId}`}
                    className="responsive-image"
                  />
                </div>
                <div className="nft-info">
                  <div className="label">Name:</div>
                  <div className="value">{tokenData.extension.name}</div>
                  <div className="label">Description:</div>
                  <div className="value">{tokenData.extension.description}</div>
                  <div className="label">View Content:</div>
                  <div className="value">
                    <a href={tokenData.extension.external_url.replace(/\/+$/, '')} target="_blank" rel="noopener noreferrer">
                      View
                    </a>
                  </div>
                  <div className="label">Attribute Data:</div>
                  <div className="value">
                    {tokenData.extension.attributes.map((attribute, index) => (
                      <p key={index}>{attribute.trait_type} : {attribute.value}</p>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="nft-data" style={{ flex: '1', overflow: 'auto' }}>
              <pre>
                {JSON.stringify(tokenData, null, 2)}
              </pre>
              <div className="block-explorer-link">
                <a
                  href={`https://terrasco.pe/testnet/address/${contractAddress}/smart/${btoa(
                    JSON.stringify({ nft_info: { token_id: tokenId } })
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block-explorer-button"
                >
                  View on Block Explorer
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
          <Footer /> {/* Moved the Footer here */}
    </div>

  </div>
);
};

export default NFTDetailView;
