import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import your CSS for styling

// Import your custom SVG icons from the public directory with relative paths
import ArrowRightIcon from '../images/icons/angle-right.svg';

const ButtonWithIcon = ({ icon, text, to }) => {
  return (
    <Link to={to} className="link-button">
      <div className="link-content">
        <img src={icon} alt={text} className="item-icon" />
        <div className="text">{text}</div>
        <img src={ArrowRightIcon} alt="Arrow Right" className="arrow-icon" />
      </div>
    </Link>
  );
};

export default ButtonWithIcon;
