import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Gallery from './components/Gallery';
import NFTDetailView from './components/NFTDetailView'; // Import NFTDetailView

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery/:section" element={<Gallery />} />
        {/* Define the route for NFTDetailView */}
        <Route path="/nft-content/:contractAddress/:tokenId" element={<NFTDetailView />} />
        {/* Redirect /nfts to / */}
        <Route path="/nfts" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
